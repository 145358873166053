@import '../../style/custonVariable';
.MainContainer{
    position: relative;
    // background: $panel-bg-color;
    height: 100%;
    margin-top: $tiles-gap-height;
    @media (max-width: 600px) { 
        margin-top: 3px;
      }
    .payoutTiles{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
        grid-gap: 9px;
        margin-top: 3px;
        padding: 11px 12px;
        margin: 0 auto 0px auto;
        max-width: 100%;
        // @media (min-width: 1600px) { 
        //     max-width: 80%;
        //   }
        @media (max-width: 991px) { 
            grid-template-columns: 1fr;
          }
        .Tiles{
            min-height: $tiles-min-height;
            background-color: $panel-bg-color;
            padding: 22px 20px;
            border-radius: 3%;
            position: relative;
            box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
            align-items: center;
            margin-bottom: 0px;
            margin-top: -$tiles-gap-height+10;
            @media (max-width: 600px) { 
                min-height:90px;
                padding: 15px 20px;
              }
            @media (max-width: 991px) {
                margin-top: 0;
              }
            .leftIcon{
                padding: 10px;
                margin-right: 10px;
                border-radius: 10%;
                width: 55px;
                height: 55px;
                display: table;
                align-items: center;
                &.green{
                    background: linear-gradient(to top, #5bc554 0%, #11a666 100%);               
                    }
                &.blue{
                        background: linear-gradient(45deg, #44badc, #519eaf);
                    }
                &.red{
                        background: linear-gradient(45deg, #e92222cc,#bd2727);
                    }
                &.yellow{
                    background: linear-gradient(45deg, #f3e214, #e7d820);
                }
                img{
                    width: 100%;
                }
            }
            .tilesData{
                h4{
                    font-family: $font-mulish !important;
                    font-size: 17px;
                    color:  rgb(88, 102, 110);
                }
                .TilesAmount{
                    color: rgb(88, 102, 110);
                    font-size: 22px;
                }
            }
        }
    }
    .summary{
        padding: 20px;
        position: relative;
        margin-bottom: 50px;
        .tabContent{
            flex-wrap: wrap;
            width: 100%;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            padding: 25px;
            background: #fff;
            // border-radius: 1px;  
            border-radius: 2%;  
            .dataTable{
                padding-top: 15px;
                .tables{
                    padding-top: 10px;
                    font-family: system-ui;
                   
                }
.exportButton{
    display: inline;
    float: right;
    button{
        background-color: $color-primary;
        height: $input-height;
        border-color: $color-primary;
        border-radius: 0.1875rem;
        margin-right: 0.333em;
        margin-bottom: 0.333em;
      
        @media (max-width:600px) {
            margin-right: 8px;
            margin-top: 10px;
            margin-bottom: 5px;
          }
        i{
            text-align: center;
            background-color: rgba(0,0,0,0.1);
            border-radius: 2px 0 0 2px;
            position: relative;
            float: left;
            width: 30px;
            height: 30px;
            margin: -6px -12px;
            margin-right: -12px;
            margin-right: -12px;
            margin-right: 3px;
            line-height: 30px;
        }
    }
}
                .filterDropDown{
                    height: 36px !important;
                    min-width: 155px;
                    border-radius: 0px !important;
                    border: 1px solid #cfdadd !important;
                    width: 150px;
                    display: inline-block;
                    .selectedCatgory{
                        position: relative;
                        width: 100%;
                        height: 100%;
                        padding: 5px 4px !important;
                        cursor: pointer;
                    display: inline-block;
                    .caretIcon{
                            display: inline-block;
                            width: 0;
                            height: 0;
                            margin-left: 5px;
                            vertical-align: middle;
                            border-top: 4px dashed;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                            position: absolute;
                            right: 10%;
                            top: 40%;
                        }
                    }
                }
                .filterSection{
                    display: inline-block; 
                    .filterButtons{
                        display: inline-block;
                        button{
                            padding: 10px;
                            margin-left: 5px;
                            @media (max-width:600px) {
                                margin-top:10px; margin-bottom: 8px;
                              }
                        }
                    }
                }
                .popupButtonArea{
                    background-color: #201f2b;
                    width: max-content;
                    padding: 12px 25px;
                    border-radius: 30px;
                    text-align: center;
                    position: fixed;
                    bottom: 2%;
                    z-index: 1111;
                    right: 0px;
                    left: 230px;
                    margin-right: auto;
                    margin-left: auto;
                    @media (min-width: 1400px) {
                            width: 25%; 
                    }
                    ul{
                        padding: 0;
                        list-style: none;
                        margin-bottom: 0;
                        li{ 
                            display: inline-flex;
                            margin-right: 15px;
                            color: #fff;
                            font-size: 18px;
                            span i{
                                cursor: pointer;
                            }
                            .CountRound{
                                background-color: #3c3c4c;
                                width: 38px;
                                height: 24px;
                                padding: 11px;
                                border-radius: 13px;
                                color: #fff;
                                font-size: 16px;
                                display: block;
                                line-height: 1px;
                                margin-right: 15px;
                                font-weight: 600;
                            }
                            .spanText{
                                height: 24px;
                                padding: 11px;
                                color: #fff;
                                font-size: 16px;
                                display: block;
                                line-height: 1px;
                                margin-right: 15px;
                            }
                            .CancelButton{
                                background-color: #ec3751 !important;
                                border: none;
                                border-radius: 30px;
                                padding: 10px 15px;
                            }
                        }
                    }
                }
            }
        }  
    }
} 


//badge style
.badge{
    display: inline-block;
    padding: .35em .5em;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3.25rem;
    min-width: 10px;
    text-shadow: 0 1px 0 rgba(252, 213, 213, 0.2);
    &.amount{
        color: #7266ba;
        background-color: #7266ba54;
    }
    &.balanceAmount{        
        color: #11a666;
        background-color: #dbf2e8;
    }
    &.debit{
        color: #f85974;
        background-color: #f6dee2;
    }
}
.ModalDialog{
    position: fixed;
    margin: auto;
    width: 50%;
    height: 100%;
    right: 0;
    max-width: 50%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    .ModalHeader{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        button{
            // margin-right: 25px;
            margin-right: -6%;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #b9b6b6;
        }
    }
    .ModalBodyHeader{
        font-size: 30px;
        color: #3b5698;
        margin-top: 0px;
        padding-bottom: 18px;
    }
}
.required::after{
    content: "*";
    color: red;
    padding-left: 5px;
}
.invalidForm{
    &:invalid{
        border-color: #dee2e6 !important;
    }
    &:valid{
        border-color: #dee2e6 !important;
        background-image: none !important;
    }
}
.invalidFeedBack{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.tableBody{
    background: #f6f8f8;
    color: #131e25;
}
.button{
    height: $input-height;
    background-color: $color-primary;
}



.dark_mode_tableBody{
    //background: #f6f8f8;
    color: #ffffff !important;
}
.button{
    height: $input-height;
    background-color: $color-primary;
}



/*********************************** DARK MODE ************************************/

.MainContainer{
    position: relative;
    // background: $panel-bg-color;
    height: 100%;
    margin-top: $tiles-gap-height;
    @media (max-width: 600px) { 
        margin-top: 3px;
      }
    .payoutTiles{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
        grid-gap: 9px;
        margin-top: 3px;
        padding: 11px 12px;
        margin: 0 auto 0px auto;
        max-width: 100%;
        // @media (min-width: 1600px) { 
        //     max-width: 80%;
        //   }
        @media (max-width: 991px) { 
            grid-template-columns: 1fr;
          }
        .dark_mode_Tiles{
            min-height: $tiles-min-height;
            background-color: hsla(0, 0%, 100%, .058823529411764705) !important;
            padding: 22px 20px;
            border-radius: 3%;
            position: relative;
            box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
            align-items: center;
            margin-bottom: 0px;
            margin-top: -$tiles-gap-height+10;
            @media (max-width: 600px) { 
                min-height:90px;
                padding: 15px 20px;
              }
            @media (max-width: 991px) {
                margin-top: 0;
              }
            .leftIcon{
                padding: 10px;
                margin-right: 10px;
                border-radius: 10%;
                width: 55px;
                height: 55px;
                display: table;
                align-items: center;
                &.green{
                    background: linear-gradient(to top, #5bc554 0%, #11a666 100%);               
                    }
                &.blue{
                        background: linear-gradient(45deg, #44badc, #519eaf);
                    }
                &.red{
                        background: linear-gradient(45deg, #e92222cc,#bd2727);
                    }
                &.yellow{
                    background: linear-gradient(45deg, #f3e214, #e7d820);
                }
                img{
                    width: 100%;
                }
            }
            .dark_model_tilesData{
                h4{
                    font-family: $font-mulish !important;
                    font-size: 17px;
                    color:  white !important;
                }
                .TilesAmount{
                    color: white !important;
                    font-size: 22px;
                }
            }
        }
    }
    .summary{
        padding: 20px;
        position: relative;
        margin-bottom: 50px;
        .dark_mode_tabContent{
            flex-wrap: wrap;
            width: 100%;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            padding: 25px;
            background: #0F2036 !important;
            // border-radius: 1px;  
            border-radius: 2%;  
            .dataTable{
                padding-top: 15px;
                .tables{
                    padding-top: 10px;
                    font-family: system-ui;
                   
                }
.exportButton{
    display: inline;
    float: right;
    button{
        background-color: $color-primary;
        height: $input-height;
        border-color: $color-primary;
        border-radius: 0.1875rem;
        margin-right: 0.333em;
        margin-bottom: 0.333em;
      
        @media (max-width:600px) {
            margin-right: 8px;
            margin-top: 10px;
            margin-bottom: 5px;
          }
        i{
            text-align: center;
            background-color: rgba(0,0,0,0.1);
            border-radius: 2px 0 0 2px;
            position: relative;
            float: left;
            width: 30px;
            height: 30px;
            margin: -6px -12px;
            margin-right: -12px;
            margin-right: -12px;
            margin-right: 3px;
            line-height: 30px;
        }
    }
}
                .filterDropDown{
                    height: 36px !important;
                    min-width: 155px;
                    border-radius: 0px !important;
                    border: 1px solid #cfdadd !important;
                    width: 150px;
                    display: inline-block;
                    .selectedCatgory{
                        position: relative;
                        width: 100%;
                        height: 100%;
                        padding: 4px 1px !important;
                        cursor: pointer;
                    display: inline-block;
                    .caretIcon{
                            display: inline-block;
                            width: 0;
                            height: 0;
                            margin-left: 5px;
                            vertical-align: middle;
                            border-top: 4px dashed;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                            position: absolute;
                            right: 10%;
                            top: 40%;
                        }
                    }
                }
                .filterSection{
                    display: inline-block; 
                    .filterButtons{
                        display: inline-block;
                        button{
                            padding: 10px;
                            margin-left: 5px;
                            @media (max-width:600px) {
                                margin-top:10px; margin-bottom: 8px;
                              }
                        }
                    }
                }
                .popupButtonArea{
                    background-color: #201f2b;
                    width: max-content;
                    padding: 12px 25px;
                    border-radius: 30px;
                    text-align: center;
                    position: fixed;
                    bottom: 2%;
                    z-index: 1111;
                    right: 0px;
                    left: 230px;
                    margin-right: auto;
                    margin-left: auto;
                    @media (min-width: 1400px) {
                            width: 25%; 
                    }
                    ul{
                        padding: 0;
                        list-style: none;
                        margin-bottom: 0;
                        li{ 
                            display: inline-flex;
                            margin-right: 15px;
                            color: #fff;
                            font-size: 18px;
                            span i{
                                cursor: pointer;
                            }
                            .CountRound{
                                background-color: #3c3c4c;
                                width: 38px;
                                height: 24px;
                                padding: 11px;
                                border-radius: 13px;
                                color: #fff;
                                font-size: 16px;
                                display: block;
                                line-height: 1px;
                                margin-right: 15px;
                                font-weight: 600;
                            }
                            .spanText{
                                height: 24px;
                                padding: 11px;
                                color: #fff;
                                font-size: 16px;
                                display: block;
                                line-height: 1px;
                                margin-right: 15px;
                            }
                            .CancelButton{
                                background-color: #ec3751 !important;
                                border: none;
                                border-radius: 30px;
                                padding: 10px 15px;
                            }
                        }
                    }
                }
            }
        }  
    }
} 


//badge style
.badge{
    display: inline-block;
    padding: .35em .5em;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3.25rem;
    min-width: 10px;
    text-shadow: 0 1px 0 rgba(252, 213, 213, 0.2);
    &.amount{
        color: #7266ba;
        background-color: #7266ba54;
    }
    &.balanceAmount{        
        color: #11a666;
        background-color: #dbf2e8;
    }
    &.debit{
        color: #f85974;
        background-color: #f6dee2;
    }
}
.ModalDialog{
    position: fixed;
    margin: auto;
    width: 50%;
    height: 100%;
    right: 0;
    max-width: 50%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    .ModalHeader{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        button{
            // margin-right: 25px;
            margin-right: -6%;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #b9b6b6;
        }
    }
    .ModalBodyHeader{
        font-size: 30px;
        color: #3b5698;
        margin-top: 0px;
        padding-bottom: 18px;
    }
}
.required::after{
    content: "*";
    color: red;
    padding-left: 5px;
}
.invalidForm{
    &:invalid{
        border-color: #dee2e6 !important;
    }
    &:valid{
        border-color: #dee2e6 !important;
        background-image: none !important;
    }
}
.invalidFeedBack{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.tableBody{
    background: #f6f8f8;
    color: #131e25;
}
.button{
    height: $input-height;
    background-color: $color-primary;
}
.dark_mode_tilesData{
    color:white !important;
}
@media (max-width: 768px) { 
    .payout_tiles_section {
        width: 100%!important;
    }
    .filterSection, .filterButtons, .exportButton {
        display: block!important;
    }
    .filterDropDown {
        width: 100%!important;
    }
    .filterButtons, .exportButton {
        text-align: center;
        float: none!important;
    }
}

/******************************** END OF DARK MODE *******************************/