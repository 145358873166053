
#root {
    $is-login-contain-width: '500px';
    $is-login-contain-padding:'30px 30px 25px 30px';
    $is-login-contain-margin-top:'7%';
}


.app{
    position: relative;
    width: 100%;
    height: 100vh;
    // height: 100%;
    min-height: 100%;
    background:#fff;
    &:after{
        position: absolute;
        top: 0; 
        bottom: 0;
        z-index: -1;
        display: block;
        width: inherit;
        background-color: #fff; 
        border: inherit;
        content: "";
    }
}
.loginContainer{
    // width: 500px !important;
    // width: 75% !important;
    width: var(--is-login-contain-width);
    max-width: 100%;
    background: rgba(255,255,255,1) !important;
    padding: var(--is-login-contain-padding);
    border-radius: 2px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // box-shadow: 0px 1px 8px rgb(0 0 0 / 20%);
    margin-top:var(--is-login-contain-margin-top);
    position: relative;
    .langContainer{display: block !important;}
  }

  .dark_mode_lg_login_btn_btm_1{
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 20px;
    font-size: 14px;
    text-decoration: none;
    a{
        width: 100%;
        text-align: center;
        color: #70A9FF;
        font-size: 14px;
        text-decoration: none;
        &:hover{color: #3b6fbd;}
    }
}

.login_lang_box {
    border: 1px solid red !important;
}

/*********************************************** dark mode **************************************/ 
.dark_mode_app{
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    background:#061E40 !important;
    &:after{
        position: absolute;
        top: 0; 
        bottom: 0;
        z-index: -1;
        display: block;
        width: inherit;
        background-color: #061E40 !important; 
        border: inherit;
        content: "";
    }
}
.dark_mode_loginContainer{
    // width: 500px !important;
    width: var(--is-login-contain-width);
    max-width: 100%;
    background:#ffffff0d !important;
    padding: var(--is-login-contain-padding);
    border-radius: 2px;
    margin-top:var(--is-login-contain-margin-top);
    position: relative;
    .langContainer{display: block !important;}
    input{background-color: rgba(255,255,255,0.2);color: #fff;}
  }

  .lg_login_btn_btm_1{
    width: 100%;
    text-align: center;
    color: #242424;
    margin-top: 20px;
    font-size: 14px;
    text-decoration: none;
    a{
        width: 100%;
        text-align: center;
        color: #70A9FF;
        font-size: 14px;
        text-decoration: none;
        &:hover{color: #3b6fbd;}
    }
}

/********************************************* end dark mode ************************************/
  
  
// NEW UPDATES
.transPasswordContainer{
    // width: 500px !important;
    // width: 75% !important;
    width: var(--is-login-contain-width);
    max-width: 100%;
    background: rgba(255,255,255,1) !important;
    padding: var(--is-login-contain-padding);
    border-radius: 2px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // box-shadow: 0px 1px 8px rgb(0 0 0 / 20%);
    margin-top:var(--is-login-contain-margin-top);
    position: relative;
    .langContainer{display: block !important;}
  }

  @media screen and (max-width: 1024px) {
    .loginContainer {
        min-width: 96%!important;
    }
  }

  @media screen and (max-width: 1170px) and (min-width: 769px) {
    .loginContainer {
        width: 86%!important;
    }
  }
  
  @media screen and (max-width: 992px) and (min-width: 777px) {
    .login_form_content {
        display: block!important;
    }
  }