@import '../../style/custonVariable';
.invoiceContent{
    padding: 15px;
    .printButton{
        text-align: right;
        margin-bottom: 5px;
        .btnAddon {
            i {
                position: relative;
                float: left;
                width: 34px;
                height: 34px;
                margin: -7px -16px;
                margin-right: 5px;
                line-height: 34px;
                text-align: center;
                background-color: rgba(0,0,0,0.1);
                border-radius: 2px 0 0 2px;        
            }
        }        
    }
    .panel{
        // padding: 15px;
        .panelBody{
            padding: 10px;
            .logoImg{
                img{
                    width: 115px;
                    vertical-align: middle;
                }
            }
            h4,h5{
                margin-top: 20px;
                margin-bottom: 10px;
            }
            .well{
                background-color: transparent;
                border-color: #dee5e7;
                min-height: 20px;
                padding: 19px;
                margin-bottom: 20px;
            
                border: 1px solid #e3e3e3;
                border-radius: 4px;
                address{
                    margin-bottom: 20px;
                    font-style: normal;
                    line-height: 1.42857143;
                    // color: black !important;
                }
            }
            
        }
    }
}
//repurchase report styles
.reportContiner{
    padding: 15px;
    .panel{
        .panelBody{
            padding: 15px;
            .dataTalbes{
                padding: 20px;
                position: relative;
                .badge{
                    display: inline-block;
                    padding: .35em .5em;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: baseline;
                    border-radius: 3.25rem;
                    min-width: 10px;
                    text-shadow: 0 1px 0 rgba(252, 213, 213, 0.2);
                    &.primaryBadge{        
                        color: #7266ba;
                        background-color: #7266ba54;
                    }
                }
            }
        }
    }
}
.btnPrimary{
    background: $color-primary;
    border-color: $color-primary;
    height: 34px;
    &:not(:disabled):not(.disabled):active,&:hover,&:disabled {
        background: $color-primary;
        border-color: $color-primary;
    }
}
.btnInfo{
    background: $color-info;
    border-color: $color-info;
    height: 34px;
    &:not(:disabled):not(.disabled):active,&:hover,&:disabled {
        background: $color-info;
        border-color: $color-info;
    }
}

.width100{
    width:100%;
}

.exportButton{
    display: inline;
    float: right;
    button{
        background-color: $color-primary;
        height: $input-height;
        border-color: $color-primary;
        border-radius: 0.1875rem;
        margin-right: 0.333em;
        margin-bottom: 0.333em;
        @media (max-width:600px) {
            margin-right: 8px;
            margin-top: 10px;
            margin-bottom: 5px;
          }
        i{
            text-align: center;
            background-color: rgba(0,0,0,0.1);
            border-radius: 2px 0 0 2px;
            position: relative;
            float: left;
            width: 30px;
            height: 30px;
            margin: -6px -12px;
            margin-right: -12px;
            margin-right: -12px;
            margin-right: 3px;
            line-height: 30px;
        }
    }
}

@media (max-width:600px) {
    .repurchase_filter_btn{
        margin-top: 5px;
    }
    .repurchase_filter_btn, .exportButton {
        display: flex;
        justify-content: center;
        float: none!important;
    }
}
