
@import "../../node_modules/@fontsource/roboto/400.css";
@import "../../node_modules/@fontsource/poppins/400.css";

:root {
    --bgPrimaryColor: #1c243a;
    --primaryColor:#CBD5E1;
    --headingColor:#94A3B8;
    --mainContentColor:#242c44;
    --cardBgColor:#28334e;
    // --btnPrimaryBgColor:#303D5D;
    // --btnPrimaryColor:#CBD5E1;
    --btnPrimaryBgColor:#3640d2;
    --btnPrimaryColor:#FFFFFF;
    --btnSecondaryBgColor:#323b5b;
    --btnSecondaryColor:#c1cad7;
    --btnDarkBgColor:#1c243a;
    --btnDarkColor:#c4cdd9;
    --btnInfoBgColor:#138496;
    --btnInfoColor:#c4cdd9;
    --color-slate-400:#94a3b8;
    --colorWhite:#ffffff;
    --borderBottomColor:#ffffff0d;
    --tableRowBorderColor:#354567;
    --tableHeadBgColor:#35456780;
    --formElementBgColor:#1c243a;
    --formElementColor:#7d899e;
    --text-white:#FFFFFF;
    --text-black:#000000;
}
html {
    background-color: var(--bgPrimaryColor)!important;
    body {
        font-family: "Roboto"!important;
        background-color: transparent!important;
        background-image: none!important;
        .topbar {
            background-color: transparent!important;
            .navmenu {
                display: none;
            }

            @media only screen and (max-width: 768px) {
                .navmenu {
                    display: block!important;
                }
            }
        }
        .sidebar {
            width: 250px;
            background-color: transparent!important;
            box-shadow: none!important;
            margin-top: 1.5%;
            // padding: 0px 15px;
            padding: 0px 0px;
            .sidebar-logo {
                border-bottom: 1px solid var(--borderBottomColor);
                // padding: 0.5rem 1rem;
                padding: 5px 0px;
                display: flex;
                justify-content: start;
                padding-left: 5px;
                div {
                    // height: 50px;
                    height: 40px;
                    padding: 5px;
                    margin: 5px;
                    img {
                        height: inherit;
                    }
                }
            }
            .menu-title {
                font-size: 13.5px!important;
                padding-top: 0px!important;
                padding-bottom: 5px!important;
                padding-left: 20px!important;
                line-height: 0px!important;
            }
            .nav-link {
                .menu-icon {
                    background-color: transparent!important;
                    color: var(--color-slate-400)!important;
                    width: 30px!important;
                    height: 30px!important;
                    line-height: 30px!important;
                }
                ::before {
                    background-color: transparent!important;
                }
            }
            .nav:not(.sub-menu) > .nav-item > .nav-link {
                padding: 25px 20px!important;
            }
            .menu-title, .nav-link {
                color: var(--color-slate-400)!important;
            }
            .nav-item {
                // height: 50px!important;
            }
            .nav-item.active {
                border: 0px solid red;
                background-color: #252b43!important;
                position: relative;
                border-radius: 30px 0px 0px 30px;
            }
            .nav-item.active::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23232D45'/%3E%3C/svg%3E%0A");
                content: "";
                width: 30px;
                height: 30px;
                margin-top: -30px;
                transform: rotate(90deg) scale(1.04);
                background-size: 100%;
                // background-image: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23f1f5f8'/%3E%3C/svg%3E%0A);
                position: absolute;
                top: 0px;
                right: 0px;
                margin-right: 0;
            }
            .nav-item.active::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23232D45'/%3E%3C/svg%3E%0A");
                content: "";
                width: 30px;
                height: 30px;
                margin-top: 50px;
                transform:scale(1.04);
                background-size: 100%;
                // background-image: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23f1f5f8'/%3E%3C/svg%3E%0A);
                position: absolute;
                top: 0px;
                right: 0px;
                margin-right: 0;
            }
            .nav-item.active .menu-icon, .nav-item.active .menu-title{
                color: var(--primaryColor)!important;
                font-weight: 500!important;
            }
        }
        .main-content {
            border:0px solid white;
            // width: calc(97% - 200px);
            // margin: 0px 1.5%;
            // width: calc(98% - 250px);
            width: calc(99% - 250px);
            margin: 0 0%;
            padding: 0 22px;
            margin-top: 1.5%;
            border-radius: 30px;
            background-color: var(--mainContentColor)!important;
            right:1%!important;
            .navbar {
                left: 0px;
                border-bottom: 1px solid #ffffff0d;
                padding: 5px 0px;
                .topbar {
                    border: 0px solid red;
                    position: inherit;
                    background: transparent;
                    padding: 5px 0px;
                    height: 50px;
                    .row {
                        height: 100%;
                        align-items: center;
                        .login-user {
                            color: var(--primaryColor);
                            font-family: "Roboto";
                            font-size: 15px;
                            font-weight: 400;
                            text-transform: capitalize;
                        }
                    }
                    .right-section {
                        gap:10px!important;
                    }
                    .profile-toggle {
                        background-color: transparent!important;
                        border: none!important;
                        width: max-content!important;
                        padding: 0px;
                    }
                    .dropdown-menu {
                        border-color: var(--bgPrimaryColor)!important;
                        background-color: var(--bgPrimaryColor)!important;
                        box-shadow: 0px 1px 15px 1px #1c243a!important;
                        .dropdown-item {
                            color: var(--primaryColor)!important;
                        }
                    }
                }
            }
            .content-wrapper {
                padding: 20px 0px;
            }
        }
        .modal-content {
            background-color: var(--cardBgColor)!important;
        }
        .treeview-content {
            li {
                background-color: var(--cardBgColor)!important;
                border-radius: 10px!important;
                margin-bottom: 5px!important;
                border:0px!important;
                figure {
                    box-shadow: none!important;
                    padding: 5px!important;
                    background-color: var(--bgPrimaryColor)!important;
                    .second-caption {
                        color: var(--primaryColor)!important;
                    }
                    .last-caption {
                        box-shadow: none!important;
                        background-color: var(--cardBgColor)!important;
                        display: flex!important;
                        justify-content: center!important;
                    }
                }
            }
            li:after, li:before {
                border-color:#138496!important;
            }
        }
        // GLOBAL USED CLASSES, TAGS START
        .edit-button, .edit-button:hover {
            border: 1px solid var(--bgPrimaryColor)!important;
            background: var(--bgPrimaryColor)!important;
        }
        .btn-primary, .btn-primary:hover {
            background-color: var(--btnPrimaryBgColor)!important;
            border: 1px solid var(--btnPrimaryBgColor)!important;
            color: var(--btnPrimaryColor)!important;
        }
        .btn-secondary, .btn-secondary:hover {
            background-color: var(--btnSecondaryBgColor)!important;
            border: 1px solid var(--btnSecondaryBgColor)!important;
            color: var(--btnSecondaryColor)!important;
        }
        .btn-dark, .btn-dark:hover {
            background-color: var(--btnDarkBgColor)!important;
            border: 1px solid var(--btnDarkBgColor)!important;
            color: var(--btnDarkColor)!important;
        }
        .btn-info, .btn-info:hover {
            background-color: var(--btnInfoBgColor)!important;
            border: 1px solid var(--btnInfoBgColor)!important;
            color: var(--btnInfoColor)!important;
        }
        .btn-info-2, .btn-info-2:hover {
            background-color: #82CCFA!important;
            border: 1px solid #82CCFA!important;
            color: #000000!important;
        }
        .body-btn-outline, .body-btn-outline:hover {
            margin: 0px 5px;
            border: 1px solid var(--color-slate-400)!important;
            background: none!important;
            border-radius: 2.5px!important;
            color: var(--color-slate-400)!important;
            font-weight: 400!important;
            font-size: 13px!important;
            text-align: center!important;
            padding: 5px 10px;
        }
        .body-form-element, .body-form-element .form-control, .form-control, .input-group-text {
            background-color: var(--formElementBgColor)!important;
            color: var(--formElementColor)!important;
            border: 1px solid var(--formElementBgColor)!important;
            outline: 1px solid var(--formElementBgColor)!important;
        }
        .btn-bg-white, .btn-bg-white:hover {
            background-color: #ffffff!important;
            border: 1px solid #ffffff!important;
        }
        .btn-bg-black, .btn-bg-black:hover {
            background-color: #000000!important;
            border: 1px solid #000000!important;
            color: #ffffff!important;
        }
        .material-modal {
            .modal-content {
                background-color: var(--mainContentColor)!important;
            }
        }
        .default-body-card {
            background-color: var(--cardBgColor)!important;
            border-radius: 10px!important;
            box-shadow: none!important;
            .modal-content {
                .close {
                    background-color: var(--bgPrimaryColor)!important;
                }
                h3 {
                    margin-bottom: 15px!important;
                }
            }
        }
        .card-bg-color {
            background-color: var(--cardBgColor)!important;
            border-radius: 10px!important;
        }
        .card {
            background-color: none!important;
            background: none!important;
        }
        .body-card {
            background-color: var(--cardBgColor)!important;
            border-radius: 10px!important;
            z-index: calc(50 - 2);
            opacity: 0;
            position: relative;
            transform: translateY(50px);
            animation: 0.4s intro-y-animation ease-in-out 0.33333s;
            animation-fill-mode: forwards;
            animation-delay: calc(2 * 0.1s);
            box-shadow: none!important;
            .list-group-item {
                background-color: #252b43 !important;
                border: 1px solid var(--btnInfoColor)!important;
                border-radius:5px!important;
            }
            .bg-trasparent {
                background-color: transparent!important;
                background: transparent!important;
            }
            .navbar-expand-lg {
                border-right: none!important;
            }
            .navbar-expand-lg::before {
                border: none!important;
            }
            div[class^='mail_headerContent'] {
                background-color: var(--mainContentColor)!important;
            }
            div[class^='mail_noListItems'] {
                background-color:transparent!important;
                border:none!important;
            }
            .mailbox-nav{
                .nav-link {
                    background-color: transparent!important;
                    border: 1px solid #abd4e021;
                    margin-top: 2px;
                }
                .nav-link.active {
                    background-color: #252b43!important;
                }
            }
            div[class^='tools_inviteContainer'] {
                .nav-item {
                    border:0px!important;
                    border-radius: 0px!important;
                }
            }
            .reg-sptes {
                span {
                    color: var(--text-black)!important;
                }
            }
            div[class^='Common_DateRangePicker'] {
                background: var(--mainContentColor)!important;
                border: 1px solid var(--btnInfoBgColor)!important;
                border-radius: 5px!important;
            }
            div[class^='CalendarContainer'] {
                background-color: var(--cardBgColor)!important;
                border: none!important;
                div[class^='CalendarHeading'] {
                    background-color: var(--cardBgColor)!important;
                }
                span, div[class^='Wrapper-sc'] {
                    background-color: var(--cardBgColor)!important;
                }
                span[class^='DateView__Selected'] {
                    background-color: var(--btnDarkBgColor)!important;
                    color: var(--btnDarkColor)!important;
                }
            }
            .package-description, .package-description p, package-description * {
                color: var(--text-white)!important;
            }
            .package-join-button {
                margin-top: 10px!important;
                font-size: 15px!important;
                font-weight: 400!important;
            }
            .material-card {
                background-color: var(--mainContentColor)!important;
                .btn, .btn:hover {
                    background-color: var(--btnInfoBgColor);
                    color: var(--btnInfoColor);
                    letter-spacing: 1px;
                    font-weight: 400!important;
                    border: none!important;
                }
            }
            div[class^='tools_detailsData'] {
                a {
                    color: varvar(--text-black)!important;
                }
            }
            .primary-color {
                color: var(--primaryColor)!important;
            }
            .primary-bg-color {
                background-color: var(--bgPrimaryColor)!important;
            }
            .earn_n_team_tabs, .earn_n_team_content {
                background-color: transparent!important;
            }
            .img-div:after {
                background:none!important;
            }
            .table-responsive {
                background-color: var(--cardBgColor)!important;
                border-radius: 10px!important;
                padding: 0px!important;
                .table thead th {
                    color: var(--color-slate-400)!important;
                    border-bottom: 1px solid var(--borderBottomColor)!important;
                }
                .table tbody td {
                    border-top: 0px!important;
                    color: var(--text-white)!important;
                }
            }
            .nav-tabs {
                .nav-item {
                    // background-color: var(--btnPrimaryBgColor)!important;
                    // border:1px solid var(--btnPrimaryBgColor)!important;
                    // background-color: var(--btnInfoBgColor)!important;
                    // border:1px solid var(--btnInfoColor)!important;
                    background-color: #82CCFA!important;
                    border:1px solid #82CCFA!important;
                    border-radius: 10px 10px 0px 0px !important;
                    font-family: "Roboto"!important;
                    font-size: 14px!important;
                    font-weight: 500!important;
                    // color: var(--btnPrimaryColor)!important;
                    color: var(--text-black)!important;
                    margin-bottom: 0px!important;
                }
            }
            .dropdown .dropdown-menu {
                background-color: var(--bgPrimaryColor)!important;
                border:1px solid var(--cardBgColor)!important;
                box-shadow: 0px 1px 15px 1px var(--cardBgColor)!important;
                .dropdown-item {
                    color:var(--primaryColor)!important;
                }
            }
            div[role="tab"] {
                // background-color: var(--btnPrimaryBgColor)!important;
                // border:1px solid var(--primaryColor)!important;
                background-color: var(--mainContentColor)!important;
                border:1px solid var(--mainContentColor)!important;
                border-radius: 5px!important;
                font-family: "Roboto"!important;
                font-size: 14px!important;
                font-weight: 500!important;
                color: var(--btnPrimaryColor)!important;
                margin-bottom: 0px!important;
                padding: 5px 10px!important;
            }
            div[role="row"] > div{
                // background-color: var(--tableHeadBgColor)!important;
                background-color: var(--mainContentColor)!important;
                color:#ffffff;
            }
            div[role="row"] > div[role="gridcell"] {
                // background-color: var(--tableHeadBgColor)!important;
                background-color: #31394a!important;
                color:#ffffff;
            }
            .table th {
                background: var(--mainContentColor)!important;
                color: var(--text-white)!important;
                border:0px!important
            }
            .table td {
                color: var(--text-white)!important;
            }
            span[class^='cart_category'] {
                background-color: transparent!important;
            }
            div[class^='cart_wrapper'] {
                background:transparent!important;
            }
            .rdt_Pagination {
                background-color: var(--tableRowBorderColor)!important;
            }
            div[role="table"] > div:nth-child(2) {
                // border:1px solid red!important;
                background-color: var(--bgPrimaryColor)!important;
                color: var(--color-slate-400)!important;
            }
            div[class^='wizard_step_'] {
                background: transparent!important;
            }
            div[class^='wizard_active'] {
                background: var(--bgPrimaryColor)!important;
            }
            .heading-color {
                color: var(--headingColor)!important;
            }
            .dashboard-icons-div {
                gap: 10px!important;
                a[class^='dashboard_profilesoc_share_bx_ico'], div[class^='dashboard_profilesoc_share_bx_ico'] {
                    background-color: #000000!important;
                    color: #ffffff!important;                    
                }
            }
        }
        div[class^='daterangepicker'] {
            background-color: var(--mainContentColor)!important;
            .ranges li, .ranges li:hover {
                color: var(--text-white)!important;
            }
            .ranges li.active {
                background-color: var(--cardBgColor)!important;
            }
            .calendar-table th, .calendar-table td {
                color: var(--color-slate-400)!important;
            }
            .calendar-table {
                border: 1px solid var(--cardBgColor)!important;
                background-color: var(--cardBgColor)!important;
                
                td {
                    color: var(--text-white)!important;
                }
                td.off {
                    background-color: transparent!important;
                    color: var(--btnInfoBgColor)!important;
                }
                td.in-range {
                    background-color: var(--btnDarkBgColor)!important;
                    color: var(--text-black)!important;
                }
                td.active, td.active:hover, td.available:hover {
                    background-color: var(--borderBottomColor)!important;
                }
            }
        }
        .dashboard-top-box {
            background:linear-gradient(90deg,#000 0,#00E0FF 75%)!important;
        }
        .dashboard-bottom-box {
            background:linear-gradient(90deg,#000 0,#5E17EB 75%)!important;
        }
        .body-btn, .body-card .body-btn, .body-btn, .body-btn:hover {
            background-color: var(--btnPrimaryBgColor)!important;
            border:1px solid var(--btnPrimaryBgColor)!important;
            border-radius: 10px!important;
            font-family: "Roboto"!important;
            font-size: 14px!important;
            font-weight: 500!important;
            color: var(--btnPrimaryColor)!important;
        }
        .table-heading {
            border-bottom: 1.5px dashed var(--borderBottomColor)!important;
            color: var(--headingColor)!important;
            margin-bottom: 20px!important;
            padding-bottom: 10px!important;
        }
        .table-row {
            border-bottom:1px solid var(--tableRowBorderColor)!important;
        }
        .table-row, .table-row > div > div {
            color:var(--primaryColor)!important;
        }
        h4, h5, h6, strong {
            color: var(--colorWhite)!important;
        }
        
        span, label, .body-label {
            color: var(--primaryColor)!important;
            // color: var(--bgPrimaryColor)!important;
        }
        .tile-box:after {
            display: none!important;
        }
        .p-0 {
            padding: 0px!important;
        }
        @media (max-width:768px) {
            .fll_wdth_row_tab {
                width: inherit!important;
            }
        }
        @media (max-width:1190px) {
            .fll_wdth_row_tab {
                width: fit-content!important;
            }
        }
        .fll_wdth_row_tab .row > div {
            border: 0px solid red!important;
            display: block!important;
            // padding: 0px!important;
        }
        // GLOBAL USED CLASSES, TAGS CLOSE

        .mh-150-px {
            min-height: 150px!important;
        }
        .tile-box {
            border-radius: inherit!important;
            h6 {
                font-size: 15px;
                margin-bottom: 0px;
            }
        }
        .bg-casal {
            background: #2E5B71!important;
        }
        .bg-skye-blue {
            // background-color: #94c2f8!important;
            background: linear-gradient(to right, #000000 0%, #2482FF 75%) !important;
        }
        .bg-light-orange {
            // background-color: #fdbd7c!important;
            background: linear-gradient(to right, #000000 0%, #D67207 75%)!important;
        }
        .bg-light-green {
            // background-color: #7cf1b0!important;
            background: linear-gradient(to right, #000000 0%, #00e35c 75%)!important;
        }
        .bg-light-pink {
            // background-color: #fca6db!important;
            background: linear-gradient(to right, #000000 0%, #e51199 75%)!important;
        }
        .bg-pink-gradient {
            background: linear-gradient(90deg,#ffbf96,#fe7096)!important;
        }
        .bg-blue-gradient {
            background: linear-gradient(90deg,#90caf9,#047edf 99%)!important;
        }
        .bg-l-green-gradient {
            background: linear-gradient(90deg,#84d9d2,#07cdae)!important;
        }
        .bg-sky-blue-gradient {
            background: linear-gradient(90deg, #96d8ff, #707bfe)!important;
        }
        .sponcer-box {
            border-radius:10px!important;
        }
    }
}

@media only screen and (max-width: 991px) and (min-width: 769px) {
    .sidebar {
        left: 0px!important;
    }
}
// .sponcer-box > div {
//     background: linear-gradient(90deg,#84d9d2,#07cdae)!important;
// }
// DASHBOARD TILE BOX CLOSE

@media(max-width: 768px) {
    .main-content {
        width: 96%!important;
        padding: 10px!important;
        margin: 10px 3px 0px 0px!important;
    }
}
@media only screen and (max-width: 991px) and (min-width: 769px) {
    .col-md-3, .col-sm-6 {
        flex:0 0 50%;
        max-width: 50%;   
    }
}
@media(max-width:991px) {
    html body .sidebar {
        margin-top: 0px!important;
    }
    .sidebar.show {
        left: 0px!important;
        z-index: 9999!important;
        transition: 0.3s ease-in;
        background-color: #243040!important;
    }
}
@media(max-width:1024px) {
    .profile-top-row {
        display: grid!important;
        grid-template-columns: auto!important;
        .left-section {
            width: 100%!important;
            max-width: 100%!important;
        }
    }
}
// LOGIN PAGE CSS START -----
.login-page {
    border: 0px solid red!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    // background: transparent!important;
    .container {
        border:0px solid grey!important;
        // display: flex;
        width: 100%!important;
        margin: 0;
        padding: 30px 50px;
        height: 100vh;
        align-items: center;
        justify-content: end;
        background: transparent!important;
        .login-container-div {
            display: grid;
            grid-template-columns: auto auto;
            gap: 0;
            justify-content: space-between;
            .left-section {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 450px;
                .first-div {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: space-between;
                    height: 100%;
                    .download-info {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        a {
                            cursor: pointer;
                            img {
                                height: 20px;
                                width: auto!important;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
            .right-section {
                .login-form {
                    background:#242424!important;
                    // box-shadow: 2px 2px 6px 2px #000000, -4px -4px 5px 2px #2d2d2d!important;
                    box-shadow: inset 2px 2px 5px #634d4d, inset -4px -4px 5px #634d4d!important;
                    border-radius: 20px;
                    width: 100%;
                    // padding: 80px 10px;
                    // min-height: 500px;
                    .login-form-content > div {
                        padding: 15px 40px;
                    }
                    .heading {
                        display: flex;
                        justify-content: center;
        
                        h4 {
                            width: fit-content;
                            border-bottom: 3px solid #0d53e9;
                            font-size: 20px;
                        }
                    }
                    input {
                        border-radius: 25px;
                        min-height: 50px;
                        font-size: 18px !important;
                        padding: 10px 20px;
                        color: #605353a3;
                        line-height: 5px;
                        margin-top: 20px;
                        // letter-spacing: 0.25px;
                        // font-family: inherit;
                    }
                    button {
                        margin-top: 20px!important;
                        box-shadow: none !important;
                        border-radius: 25px!important;
                        background-color: #0d53e9!important;
                    }
                    .forget-link {
                        margin-bottom: 15px;
                        a {
                            color: #ffffff;
                            font-family: "poppins"!important;
                            text-decoration: underline;
                        }
                    }
                }   
            }
        }
    }
}

@media(max-width:768px) {
    .login-page {
        .container {
            padding: 30px 30px!important;
            .login-container-div {
                grid-template-columns: auto;
                justify-content: center!important;
                .left-section {
                    height: 220px!important;
                    margin-bottom: 15px;
                    .download-info {
                        display: flex;
                        flex-direction: row!important;
                        justify-content: space-between;
                    }
                }
                .right-section {
                    .login-form {
                        margin-top: 0px!important;
                    }
                }
            }
        }
    }
}
.left-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
        text-align: start;
        h1 {
            font-size: 3.5rem !important;
            color: #ffff;
            font-weight: bolder;
            margin-bottom:20px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .login-page {
        .container {
            justify-content: center!important;
        }
    }
}

@media only screen and (max-width: 480px) {
    .login-page {
        .container {
            justify-content: center!important;
            padding: 20px;
            .left-section {
                height: 180px!important;
            }
            .login-form {
                min-width: 97%!important;
            }
        }
    }
    .left-content {
        div {
            text-align: center;
            h1 {
                font-size: 2rem !important;
            }
        }
    }
    .download-info {
        a {
            img {
                height: 20px!important;
            }
            label {
                font-size: 14px!important;
            }
        }
    }
    .sidebar.show {
        left: 0px!important;
        z-index: 9999!important;
        transition: 0.3s ease-in;
        background-color: #243040!important;
    }
    .sidebar.hide {
        left: -500px!important;
        transition: 0.5s ease-out;
    }
}

// LOGIN PAGE CSS CLOSE -----




// Animation CSS Start Here -----
.bot-to-top {
    z-index: calc(50 - 2);
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: 0.4s intro-y-animation ease-in-out 0.33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(2 * 0.1s);
}

.left-to-right {
    z-index: calc(50 - 2);
    opacity: 0;
    position: relative;
    transform: translateX(50px);
    animation: 0.4s intro-x-animation ease-in-out 0.33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(2 * 0.1s);
}
@keyframes intro-y-animation {
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes intro-x-animation {
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.profile-top-row {
    .left-section {
        .img-div {
            height: 130px;
            width: 130px;
            img {
                height: inherit!important;
            }
        }
        @media(max-width:768px) {
            .img-div {
                height: 90px;
                width: 90px;
            }
        }
    }
}
.h-200-px {
    height: 200px!important;
}
// Animation CSS Close Here -----
.earn_n_team_tabs{
    color: #97a3b6;
}
.earn_n_team_activee-tabs, .earn_n_team_active-tabs {
    color:var(--text-white)!important;
}
.earn_n_team_activee-tabs::before, .earn_n_team_active-tabs::before {
    background:var(--text-white)!important;
}
.btn-bg-white {
    background-color: #ffffff!important;
}


::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
::-webkit-scrollbar-track {
    background: #ffffff;
}
::-webkit-scrollbar-thumb {
    background: #242c44;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
div[class^='dashboard_profile_main_sec'], div[class^='dashboard_profile_soc_share_sec'], div[class^='dashboard_profile_other_box__'] {
    border-right: 1px solid var(--tableRowBorderColor)!important;
}
div[class^='dashboard_profile_other_box__'] {
    span, strong {
        color: var(--bgPrimaryColor)!important;
    }
}
div[class^='tree_treeview_'] {
    overflow: auto!important;
}
.MuiChartsAxis-line, .MuiChartsAxis-tick {
    stroke:var(--tableRowBorderColor)!important;
}
.MuiChartsAxis-tickLabel {
    fill:var(--primaryColor)!important;
}

.sidebar > .nav .nav-item .sub-menu .nav-item:last-child .nav-link.active {
    background: var(--bgPrimaryColor) !important;
    color: #fff!important;
    padding: 5px 0px 5px 50px;
}


.forget-form {
    background:#242424!important;
    // box-shadow: 2px 2px 6px 2px #000000, -4px -4px 5px 2px #2d2d2d!important;
    box-shadow: inset 2px 2px 5px #634d4d, inset -4px -4px 5px #634d4d!important;
    border-radius: 20px;
    width: 100%;
    // padding: 80px 10px;
    // min-height: 500px;
    .login-form-content > div {
        padding: 15px 40px;
    }
    .heading {
        display: flex;
        justify-content: center;

        h4 {
            width: fit-content;
            border-bottom: 3px solid #0d53e9;
            font-size: 20px;
        }
    }
    input {
        border-radius: 25px;
        min-height: 50px;
        font-size: 18px !important;
        padding: 10px 20px;
        color: #605353a3;
        line-height: 5px;
        margin-top: 20px;
        // letter-spacing: 0.25px;
        // font-family: inherit;
    }
    button {
        margin-top: 20px!important;
        box-shadow: none !important;
        border-radius: 25px!important;
        background-color: #0d53e9!important;
    }
    .forget-link {
        margin-bottom: 15px;
        a {
            color: #ffffff;
            font-family: "poppins"!important;
            text-decoration: underline;
        }
    }
}