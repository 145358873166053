@import '../../style/custonVariable';
.formBox {
    display: block;
    .formWizard{
        // padding: 25px;
        background: #fff;
        -moz-border-radius: 4px;
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        .formWizardSteps{
            margin: auto;
            overflow: hidden;
            position: relative;
            margin-top: 20px;
            margin-bottom: 30px;
            .step{
                position: relative;
                float: left;
                // width: 25%;
                width: 33%;
                padding: 0 5px;
                padding-top: 10px !important;
                border: 2px solid #fff;
                background: #ccc;
                -webkit-transform: skewX(-30deg);
                transform: skewX(-30deg);
                @media (max-width: 991px) {
                    width: 100%;
                  }
                p{
                    color: #fff;
                    -ms-transform: skewX(30deg);
                    -webkit-transform: skewX(30deg);
                    transform: skewX(30deg);
                    margin: 0 0 10px;
                }
                &.active{
                    background: $color-primary;
                }
                &.activated{
                    background: $color-info;
                }
            }
        }
                .fieldSet{
                    text-align: left;
                    display: block;
                }
    }
}


/******************************** dark mode ***************************************************/

.formBox {
    display: block;
    .dark_mode_formWizard{
        // padding: 25px;
        background: hsla(0,0%,100%,0) !important;
        -moz-border-radius: 4px;
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        .formWizardSteps{
            margin: auto;
            overflow: hidden;
            position: relative;
            margin-top: 20px;
            margin-bottom: 30px;
            .dark_mode_step{
                position: relative;
                float: left;
                width: 25%;
                padding: 0 5px;
                padding-top: 10px !important;
                border: 2px solid hsla(0,0%,100%,.0784313725490196) !important;
                //background: #ccc;
                -webkit-transform: skewX(-30deg);
                transform: skewX(-30deg);
                @media (max-width: 991px) {
                    width: 100%;
                  }
                p{
                    color: #fff;
                    -ms-transform: skewX(30deg);
                    -webkit-transform: skewX(30deg);
                    transform: skewX(30deg);
                    margin: 0 0 10px;
                }
                &.active{
                    background: #7266ba !important;
                }
                &.activated{
                    background: $color-info;
                }
            }
        }
                .fieldSet{
                    text-align: left;
                    display: block;
                }
    }
}

/******************************* end of dark mode *********************************************/