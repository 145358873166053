*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }






@font-face {
  font-family: 'poppinslight';
  src: url('../../../assets/fonts/poppins-light-webfont.woff2') format('woff2'),
       url('../../../assets/fonts/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsmedium';
  src: url('../../../assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
       url('../../../assets/fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsregular';
  src: url('../../../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
       url('../../../assets/fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

} 
  
  body {
    background: #fff;
  }
  
  .nouse_container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 500px;
    height: 300px;
    /* background: #f1f1f1; */
    margin: 100px auto 0;
    word-break: break-all;
    /* border: 1px solid rgba(0, 0, 0, 0.274); */
  }
  
  .earn_n_team_containerr {
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  position: relative;
  padding: 20px;
  float: left;
  background: white;
  width: 100%;
  height: auto;
  min-height: 315px;
  /* background: #f1f1f1; */
  /* margin: 100px auto 0; */
  word-break: break-all;
  }


  .earn_n_team_bloc-tabs {
    display: flex;
    /* width: 50%; */
    
  }
  .earn_n_team_tabs {
  
    /* padding: 15px; */
    /* padding: 10px; */
    padding: 5px;
    font-size: 13px;
    /* font-size: 14px; */
    text-align: center;
    /* width: 50%; */
    background: rgba(128, 128, 128, 0.075);
    margin: 0px 20px 0px 0px;
    cursor: pointer;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
    box-sizing: content-box;
    position: relative;
    outline: none;
    text-align: left;padding-left: 10px;
  }
  
  /* .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  } */
  
  .earn_n_team_active-tabs  {
    background: white;
    color: rgb(88, 147, 241);
    /* border-bottom: 1px solid transparent; */
   
  }

  .earn_n_team_activee-tabs  {
    background: white;
    color: rgb(88, 147, 241);
    /* border-bottom: 1px solid transparent; */
    
  }
  
  /* .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: rgb(88, 147, 241);
  } */
  
  .earn_n_team_active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    /* top: 42.9px; */
    top: 30.9px;
    left: -0.5%;
    /* transform: translateY(-102%); */
    transform: translateY(-130%);
    /* width: 5px; */
    width: 3px;
    /* height: calc(100% + 1.6px); */
    height: calc(70% );
    background: rgb(88, 147, 241);
  }
  
  
  .earn_n_team_activee-tabs::before {
    content: "";
    display: block;
    position: absolute;
    /* top: 42.9px; */
    top: 4px;
    left: -0.5%;
    /* transform: translateY(-102%); */
    transform: inherit;
    /* width: 5px; */
    width: 3px;
    /* height: calc(100% + 1.6px); */
    height: calc(70% );
    background: rgb(88, 147, 241);
    text-align: left;
  }
  

  /* td{
    border: none;
  } */
  
  button {
    border: none;
    
  }
  .earn_n_team_content-tabs {
    flex-grow : 1;
  
  }
  .earn_n_team_content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .earn_n_team_content h2 {
    padding: 0px 0 5px 0px;
  }
  .earn_n_team_content hr {
    width: 80%;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .earn_n_team_content p {
    width: 100%;
    height: 20%;
  }
  .earn_n_team_active-content {
    display: block;
  }
  .earn_n_team_content:last-child .dashboard_dash_perfomencers_row{
    padding-left: 0;
  }

  .earn_n_team_activee-content {
    display: block;
  }

  @media only screen and (max-width: 907px){
    .multiselect-container{margin-bottom: 20px;width: 100% !important;     max-width: 91% !important;}
    .form-row .col-auto{width: 100%;}
    
}
  
  
  /* doit is to wrap the custom made divs within a div */
  /* .doit{
    width: 100%;
    height: 100%;
    float: left;
    border: 1px solid black;
    padding: 20px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 9px 21px rgb(0 0 0 / 3%);
    box-shadow: 0px 9px 21px rgb(0 0 0 / 3%);
    border-radius: 10px;
    position: relative;
    margin-bottom: 15px;
} */

.earn_n_team_headerinsidediv{
  /* padding: 10px; */
    margin-top: 0px; 
    margin-bottom: 15px;
}


.earn_n_team_dropdownyo_menu {
   /* right: 100px; 
   top: 407px;  */
  /* transform: translate(300px, 20px) !important; */
  /* background: purple; */


    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
 
}

.earn_n_team_dropdown_item{
  padding: 0rem 1rem;
}

.nodataheight{
    min-height: 194px;
}

.nodataheight h4 {
  text-align: center;
}

.pydata{
  position: absolute;
  right:5%;
  
}

@media screen and (max-width:500px) { 
  .earn_n_team_bloc-tabs{display: inline-block;}
  .earn_n_team_tabs{
    width: 92%;
    margin-bottom: 10px !important;
    display: inline-block;
  }
  .earn_n_team_content div{border: 0 !important;}

}

@media (max-width: 768px) {
  .multiselect-container {
      max-width: 100%!important;
  }
}