// Cards Mixins 

@mixin card-inverse-variant($bg, $border, $color) {
  background: $bg;
  border: 1px solid $border;
  color: $color;
}
@mixin bg-inverse-icon($color) {
  background: darken($color, 24%);
  border-color: $color;

  .bg-item {
    color: $color;

    &:before {
      color: inherit;
    }

    a {
      color: inherit;
    }
  }

  &.bg-custom {
    background: transparent;

    .bg-item {
      background: lighten($color, 25%);

      a {
        color: $color;
        border: none;

        &:before {
          border-left-color: lighten($color, 25%);
        }
      }

      span {
        color: $color;
      }

      &:last-child {
        background: transparent;
      }
    }
  }
}// Background Mixins //


// NEW ADDED FOR TRAVEL MODULE START -----
.travel-card {
  box-shadow: 1px 2px 9px #F4AAB9!important;
  // margin: 0px 20px!important;
}
.travel-card-div {
  border: 1px solid #0d88b8d6!important;
  background-color: #0d88b8d6!important;
  min-height: 150px!important;
}
.package-title {
  padding: 0px 0px!important;
  text-transform: capitalize;
  width: fit-content;
  font-size: 15px!important;
}
.package-title-span {
  padding: auto;
}
.ribbon-pop {
  background: linear-gradient(270deg, #4f396a 5.25%, #576bb0 96.68%);
  display: inline-block;
  padding: 10px 40px 10px 20px;
  color: white;
  position: relative;
  left:-15px!important;
  top: -1px!important;
}
.ribbon-pop:after {
  content: "";
  width: 0;
  height: 0;
  // top: 0;
  // position: absolute;
  // right: 0;
  // border-right: 20px solid #fff;
  // border-top: 20px solid transparent;
  // border-bottom: 24px solid transparent;
  top: 0px;
  position: absolute;
  right: -20px;
  border-right: 20px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  transform: rotate(180deg);
}
.ribbon-pop:before {
  height: 0;
  width: 0;
  border-top: 15px solid #381f57;
  border-left: 15px solid transparent;
  bottom: -15px;
  position: absolute;
  content: "";
  left: 0;
}
.package-installment {
  font-size: 50px!important;
  color: #ffffff;
  font-weight: bolder;
  font-family: monospace!important;
}
.package-installment span {
  font-size: 30px!important;
}
.package-month {
  background-color: #4f3c6e;
  padding: 10px;
}
.package-month span {
  font-weight: revert;
  font-size: 20px;
  margin: 0px;
  color: white;
}
.package-description {
  min-height: 360px !important;
  max-height: 360px !important;
  overflow: auto;
  text-align: justify;
  color: black;
  padding: 10px;
  text-transform: capitalize;
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 17px;
  font-family: fangsong;
  padding-top: 25px;
  padding-bottom: 10px;
}
.package-description * {
  // font-family: monospace!important;
  font-family: 'poppinsmedium';
  font-size: 12px!important;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
}
.package-description li {
  font-size: 16px;
  text-align: left;
  font-family: 'poppinsregular'!important;
  font-weight: normal;
}
.package-join-button {
  border-radius: 0px;
  font-size: 25px!important;
  font-weight: bolder;
  margin-top: 0px;
}

.theme-dark .package-description li {
  color: white;
}

@media (max-width: 767px) {
  .package-main-div {
    padding: 15px!important;
  }
  .travel-card {
    margin:10px 0px !important;
    padding:0px !important;
  }
  .travel-card-div {
    margin: 0px 0px!important;
    border: 0px!important;
  }
  .package-description {
    margin: 0px 0px!important;
  }
  .package-description li {
    font-size: 15px!important;
  }
}
// NEW ADDED FOR TRAVEL MODULE CLOSE -----