#root {
    $is-login-font-color: 'black';
}

// =================================
// auth modal css file

body{background-image: none !important;background-color: #fff !important;}
.loginNavBrand{
    width: 200px;
    margin: 0 auto 20px;
    display: none !important;   // added by sridix
}
.loginNavBrandImg{
    width: 100%;
}
.loginForms{
    // background-color: #f7f7f7 !important;
    padding: 20px 22px !important;
    // border-radius: 18px!important;
    // border: 0;
    font-size: 15px;;
    border-radius: 20px;
    // box-shadow: 2px 5px 10px #e7e7e7;
    &:focus{
        border-color: #d8d8d8;
    }
}
.loginFormsHasError{
    box-shadow: none;
    border: 1px solid #ead3d3 !important;
}
.isInvalid{
    margin-top: 4px;
    margin-left: 23px;
    color: #a94442;
}
.loginButton{
    text-align: center;
}
.loginFormBtn{
    width: auto;
    margin: 3px auto;
    padding: 10px 60px;
    // border-radius: 18px;
    color: #fff!important;
    background-color: #70A9FF!important;
    border-color: #70A9FF;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 10px 20px #bdd7ff;
}

.dark_mode_loginFormBtn{
    width: auto;
    margin: 3px auto;
    padding: 10px 60px;
    // border-radius: 18px;
    color: #4a4040 !important;
    background-color: #98cbff!important;
    border-color: #70a9ff;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    //box-shadow: 0px 10px 20px #bdd7ff;
}


//login form 
.LoginFormsContainer{
    background: rgba(255,255,255,1);
    padding: 30px 30px 25px 30px;
    border-radius: 2px;
    // box-shadow: 0px 1px 8px rgb(0 0 0 / 20%);
}
.ForgotSection{
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 9;
    margin: -10px 1px 22px;
    a{
    color: #242424;
    font-size: 15px;
    text-decoration: none;
    &:hover{color: #70A9FF;}
    }
    .admin_login_lnk{
        text-align: right;
    }

}
.lg_login_btn_btm{
    width: 100%;
    text-align: center;
    color: #242424;
    margin-top: 20px;
    font-size: 15px;
    text-decoration: none;
    a{
        width: 100%;
        text-align: center;
        color: #4690ff;
        font-size: 15px;
        text-decoration: none;
        &:hover{color: #3b6fbd;}
    }
    
 
}
.langContainer{display: block !important;}

.dark_mode_lg_login_btn_btm{
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 20px;
    font-size: 15px;
    text-decoration: none;
    a{
        width: 100%;
        text-align: center;
        color: #4690ff;
        font-size: 15px;
        text-decoration: none;
        &:hover{color: #3b6fbd;}
    }
    
 
}



.dark_mode_textArea {
    font-size: 15px;
    background-color: #e8f0fe0d !important;
    color: white !important;
    border: none;
}


.dark_mode_ForgotSection{
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 9;
    margin: -10px 1px 22px;
    a{
    color: #fff !important;
    font-size: 15px;
    text-decoration: none;
    &:hover{color: #70A9FF;}
    }
    .admin_login_lnk{
        text-align: right;
    }

}

// =========================================
// =========================================

.login_page_image {
    // border: 1px solid red !important;
    width: 100%;

    img {
        width: inherit !important;
    }
}
.brandViewSection {
    display: none;
}
.login_page_content{
    padding: 20px;
}
.already_account{
    display: block;
    width: 100%;
    text-align: end;
    min-height: 10%;
    margin-top: 15px;
}
.already_account_span {
    font-size: 15px;
    font-weight: bold;
    color: var(--is-login-font-color);
    letter-spacing: 1px;
    margin-top: 5px;
    margin-right: 5px;
}
.register_btn {
    font-size: 18px;
    background: #70a9ff !important;
    border-radius: 0px;
    border: none;
    color: white;
    padding: 7px;
}

.login_form_content {
    padding: 15px 25px;
    margin-top: 0px;
    height:90%;
    display: flex;
    .login_form_content_div {
        align-self: center;
    }
    .form_heading {
        h5 {
            font-size: 4vh;
            color: var(--is-login-font-color);
            font-weight: bold;
            font-family: 'poppinsregular' !important;
        }
    }
    .form_sub_heading {
        font-size: 1.75vh;
        color: var(--is-login-font-color);
        font-weight: lighter;
        font-family: 'poppinsregular' !important;
    }
    .form_content {
        margin-top: 0px;
        padding:5px;

        .form_label {
            font-weight: 600;
            font-size: 1.5vh;
            color: var(--is-login-font-color);
            text-transform: capitalize;
            letter-spacing: 1.2px;
            font-family: 'poppinsregular';
            margin-bottom: 0;
        }
        .form_input {
            box-shadow: 0 2px 15px -3px rgba(0,0,0,.07),0 10px 20px -2px rgba(0,0,0,.04)!important;
            font-size: 15px !important;
            height: 40px !important;
            padding: 10px;
        }
    }
    .form_forget_link {
        margin-top: 5px;
        font-weight: 500;
        font-size: 2.5vh;
        color: blue;
    }
    .form_btn {
        width: 100% !important;
        border-radius: 0px;
        padding: 10px;
        font-size: 20px;
    }
}


// modal css
.modal-dialog {
    width: 50% !important;
}


@media (max-width:768px) {
    .form_heading h5{
        font-size: 3vh!important;
        text-align: center;
        padding-top: 15px!important;
    }
    .form_sub_heading{
        text-align: center!important;
        font-size: 2vh!important;
        line-height: 1.5;
    }
    .login_page_image > img {
        display: none!important;
    }
    .brandViewSection{
        display: block!important;
    }
}
@media screen and (max-width: 992px) and (min-width: 777px) {
    .login_form_content {
        display: block!important;
    }
}
@media screen and (max-width: 1024px) {
    .login_form_content .form_heading h5 {
        font-size: 3vh!important;
    }
}